/*!
 * jsonformatter
 * 
 * Version: 0.6.0 - 2016-08-27T12:58:03.339Z
 * License: Apache-2.0
 */


.json-formatter-row {
  font-family: monospace;
}
.json-formatter-row,
.json-formatter-row a,
.json-formatter-row a:hover {
  color: black;
  text-decoration: none;
}
.json-formatter-row .json-formatter-row {
  margin-left: 1em;
}
.json-formatter-row .children.empty {
  opacity: 0.5;
  margin-left: 1em;
}
.json-formatter-row .children.empty.object:after {
  content: "No properties";
}
.json-formatter-row .children.empty.array:after {
  content: "[]";
}
.json-formatter-row .string {
  color: green;
  white-space: pre;
  word-wrap: break-word;
}
.json-formatter-row .number {
  color: blue;
}
.json-formatter-row .boolean {
  color: red;
}
.json-formatter-row .null {
  color: #855A00;
}
.json-formatter-row .undefined {
  color: #ca0b69;
}
.json-formatter-row .function {
  color: #FF20ED;
}
.json-formatter-row .date {
  background-color: rgba(0, 0, 0, 0.05);
}
.json-formatter-row .url {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.json-formatter-row .bracket {
  color: blue;
}
.json-formatter-row .key {
  color: #00008B;
  cursor: pointer;
}
.json-formatter-row .constructor-name {
  cursor: pointer;
}
.json-formatter-row .toggler {
  font-size: 0.8em;
  line-height: 1.2em;
  vertical-align: middle;
  opacity: 0.6;
  cursor: pointer;
}
.json-formatter-row .toggler:after {
  display: inline-block;
  transition: transform 100ms ease-in;
  content: "►";
}
.json-formatter-row .toggler.open:after {
  transform: rotate(90deg);
}
.json-formatter-row > a > .thumbnail-text {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  font-style: italic;
}
.json-formatter-row:hover > a > .thumbnail-text {
  opacity: 0.6;
}
.json-formatter-dark.json-formatter-row {
  font-family: monospace;
}
.json-formatter-dark.json-formatter-row,
.json-formatter-dark.json-formatter-row a,
.json-formatter-dark.json-formatter-row a:hover {
  color: white;
  text-decoration: none;
}
.json-formatter-dark.json-formatter-row .json-formatter-row {
  margin-left: 1em;
}
.json-formatter-dark.json-formatter-row .children.empty {
  opacity: 0.5;
  margin-left: 1em;
}
.json-formatter-dark.json-formatter-row .children.empty.object:after {
  content: "No properties";
}
.json-formatter-dark.json-formatter-row .children.empty.array:after {
  content: "[]";
}
.json-formatter-dark.json-formatter-row .string {
  color: #31F031;
  white-space: pre;
  word-wrap: break-word;
}
.json-formatter-dark.json-formatter-row .number {
  color: #66C2FF;
}
.json-formatter-dark.json-formatter-row .boolean {
  color: #EC4242;
}
.json-formatter-dark.json-formatter-row .null {
  color: #EEC97D;
}
.json-formatter-dark.json-formatter-row .undefined {
  color: #ef8fbe;
}
.json-formatter-dark.json-formatter-row .function {
  color: #FD48CB;
}
.json-formatter-dark.json-formatter-row .date {
  background-color: rgba(255, 255, 255, 0.05);
}
.json-formatter-dark.json-formatter-row .url {
  text-decoration: underline;
  color: #027BFF;
  cursor: pointer;
}
.json-formatter-dark.json-formatter-row .bracket {
  color: #9494FF;
}
.json-formatter-dark.json-formatter-row .key {
  color: #23A0DB;
  cursor: pointer;
}
.json-formatter-dark.json-formatter-row .constructor-name {
  cursor: pointer;
}
.json-formatter-dark.json-formatter-row .toggler {
  font-size: 0.8em;
  line-height: 1.2em;
  vertical-align: middle;
  opacity: 0.6;
  cursor: pointer;
}
.json-formatter-dark.json-formatter-row .toggler:after {
  display: inline-block;
  transition: transform 100ms ease-in;
  content: "►";
}
.json-formatter-dark.json-formatter-row .toggler.open:after {
  transform: rotate(90deg);
}
.json-formatter-dark.json-formatter-row > a > .thumbnail-text {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  font-style: italic;
}
.json-formatter-dark.json-formatter-row:hover > a > .thumbnail-text {
  opacity: 0.6;
}
